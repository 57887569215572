import React from 'react';
import { Auth } from "aws-amplify";
import { Spin } from 'antd';
// import { withApollo } from "react-apollo";
import SelectStation from './selectStation'
import { graphql, withApollo } from '@apollo/client/react/hoc';

class GetChefLogin extends React.Component {
    constructor(props){
        super(props)
        this.state={
            loading: true,
            userId: ''
        }
    }
    componentDidMount(){
        Auth.currentUserInfo()
        .then((data) => {
            //console.log('data::',data)
            this.setState({
                userId:data.username,
                siteId: data.attributes['custom:siteId'],
                loading: false
            })
        })
        .catch(err => console.log(err));
    }
    render() {
        return (
        <>
        {this.state.loading ? 
        <Spin></Spin>
        : 
            <SelectStation userId={this.state.userId} {...this.props}/>
        }
        </>
        );
    }
}

export default withApollo(GetChefLogin);