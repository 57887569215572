import gql from 'graphql-tag';
export default gql`
subscription tableOrderCreated($siteId: String!){
    tableOrderCreated(siteId: $siteId){
      id
      orderId
      custId
      parentOrderRef
      noOfGuest
      counterId
      tokenId
      externalOrderId
      aggregatorSystem
      callbackUrl
      siteId
      name
      mobile
      email
      createdBy
     reopenedAt
     syncTimestamp
      address
      date
      otp
      source
      orderFrom
      subOrderFrom
      orderEnabled
      timestamp
      taxAmt
      preparationTime
      aggregatorOrderId
      orderSource
      updatedAt
      grandTotal
      tableNo
      blockedTables
      finalAmount
      pendingOrderStatus
      orderStatus
      discountAmt
      discountType
      shippingAmount
      couponCode
      remarks
      rewardPoints
      rewardAmt
      orderCreatedFrom
      paymentMode
      paymentState
      updatedBy
      paymentStatus
      customerSegment
      rejectReason
      paidAmt
      balanceAmt
      tableOrderStatus
      isBillGenerated
      deliveryTimestamp
      qrData
      primaryWaiter
      captain
      area
      orderTags {
      tag_type
      content
      }
      rider {
        id
        name
        mobile
        status
        orderId
      }
      otherCharges{
        name
        rate
        type
        title
        tax
        amt
        defaultSelcted
      }
      taxation {
            taxType
            taxSubType
            taxRate
            taxableAmt
          }
      appliedDiscounts{
        type
        discountName
        discountType
        discountValue
        discountAmt
      }
      customFeilds{
        customName
        customType
        customValue
        required
        availability
      }
    
      orderList{
        subOrderId
        totalPrice
        createdAt
        createdBy
        services{
          serviceId
          masterProdId
          refId
          serviceName
          serviceRate
          serviceAmount
          discountAmt
          discountType
          isUrgent
          uId
          pmInventory
          departmentId
          isInvWastage
          purchaseItemRef
          attachedCat
          instruction
          qty
          reqQty
          tax
          taxAmt
          taxType
          itemType
          status
          station
          rejectReason
          kotId
          inprocessAt
          kotgeneratedAt
          cancelledAt
          preparedAt
          completedAt
          addons{
            attachedAddonCat
            serviceName
            serviceRate
             qty
             serviceId
             tax
             station
             isInventory
             pmInventory
             departmentId
             purchaseItemRef
           }
        }
        customFeilds{
          customName
          customType
          customValue
          required
        }
      }
    }
  }`

